import { createApp } from "vue";
import App from "./App.vue";

import PrimeVue from "primevue/config";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import Button from "primevue/button";
import Checkbox from "primevue/column";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InputSwitch from "primevue/inputswitch";
import InputText from "primevue/inputtext";
import Knob from 'primevue/knob';
import Panel from "primevue/panel";
import PanelMenu from "primevue/panelmenu";
import ProgressSpinner from "primevue/progressspinner";
import Tag from "primevue/tag";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Toolbar from "primevue/toolbar";
import Tooltip from "primevue/tooltip";
import TriStateCheckbox from "primevue/tristatecheckbox";

import "primeflex/primeflex.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

const app = createApp(App);

app.use(PrimeVue);
app.use(ToastService);

app.component("Accordion", Accordion);
app.component("AccordionTab", AccordionTab);
app.component("Button", Button);
app.component("Checkbox", Checkbox);
app.component("Column", Column);
app.component("DataTable", DataTable);
app.component("Dialog", Dialog);
app.component("Divider", Divider);
app.component("Dropdown", Dropdown);
app.component("FileUpload", FileUpload);
app.component("InputSwitch", InputSwitch);
app.component("InputText", InputText);
app.component("Knob", Knob);
app.component("Panel", Panel);
app.component("PanelMenu", PanelMenu);
app.component("ProgressSpinner", ProgressSpinner);
app.component("Tag", Tag);
app.component("Textarea", Textarea);
app.component("Toast", Toast);
app.component("Toolbar", Toolbar);
app.component("TriStateCheckbox", TriStateCheckbox);

app.directive("tooltip", Tooltip);

app.mount("#app");
