<template>
    <div class="surface-card p-4 shadow-2 border-round w-full">
      <div class="text-center mb-5">
        <img src="../assets/icon.png" alt="Image" height="50" class="mb-3" />
        <div class="text-900 text-3xl font-medium mb-3">Optakit Hasher</div>
        <span class="text-600 font-medium line-height-3"
          >Text description here about hashing lists.</span
        >
      </div>
    </div>
</template>

<script>

export default {
  name: "WelcomeHeader",
  data() {
    return {

    };
  },
};
</script>
