<template>
    <div class="surface-card p-4 shadow-2 border-round w-full">
      <div class="text-center">
        <!-- <img src="../assets/icon.png" alt="Image" height="50" class="mb-3" /> -->
        <span class="text-600 font-medium line-height-3"
          >A product from <a href="https://www.Optakit.com" target="_new">Optakit</a>.</span
        >
      </div>
    </div>
</template>

<script>

export default {
  name: "WelcomeHeader",
  data() {
    return {

    };
  },
};
</script>
