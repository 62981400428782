<template>
    <div id="wrapper">
  <div v-if="stage == 0" class="text-center">
    <ProgressSpinner />
  </div>
  <div v-if="stage == 1" id="app" class="grid">
      <div class="col-12 md:col-10 md:col-offset-1">
            <WelcomeHeader />
      </div>
      <div class="col-12 md:col-3 md:col-offset-1">
        <Options @optionChange="optionChange" />
      </div>
      <div class="col-12 md:col-4">
        <GenerateHash v-if="options.mode == 'generate'" />
        <CompareHash v-if="options.mode == 'compare'" />
        </div>
        <div class="col-12 md:col-3">
            <UserAuth @checkLogin="loginState" :user="user" />
        </div>
        <div class="col-12 md:col-10 md:col-offset-1">
            <Footer />
      </div>
    <Toast />
  </div>
  <div v-if="stage == 2" id="app">
    <UserAuth :user="user" />
  </div>
  <div id="stars"></div>
<div id="stars2"></div>
<div id="stars3"></div>
</div>
</template>

<script>
import WelcomeHeader from "./components/WelcomeHeader.vue";
import Options from "./components/Options.vue";
import GenerateHash from "./components/GenerateHash.vue";
import CompareHash from "./components/CompareHash.vue";
import UserAuth from "./components/Auth.vue";
import Footer from "./components/Footer.vue";
import { fireauth } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";

export default {
  data() {
    return {
      user: null,
      stage: 1,
      options: {
          mode: "generate"
      }
    };
  },
  methods: {
      showValue(value) {
        console.log("received", value);
      },
    optionChange(value) {
        this.options.mode = value;
    },
    loginState() {
      onAuthStateChanged(fireauth, (user) => {
        if (user) {
          this.user = user;
          this.requestPermission();
        } else {
          this.user = null;
        }
      });
    },
    requestPermission() {
      if (!("Notification" in window)) {
        console.log("Notification API not supported!");
        return;
      }
      Notification.requestPermission(function (result) {
        console.log("Notification permission: ", result);
      });
    },
  },
  components: {
    WelcomeHeader,
    Options,
    GenerateHash,
    CompareHash,
    UserAuth,
    Footer
  },
  mounted() {
    this.loginState();
    setTimeout(() => {
      this.stage = 1;
    }, 2000);
  },
};
</script>

<style lang="scss" scoped>
/* @import "compass";
@import "compass/functions";  */
/* @import "compass/css3"; */
/* @import "compass/css3/animation"; */

@function multiple-box-shadow ($n) {
    $value: '#{random(2000)}px #{random(2000)}px #FFF';
    @for $i from 2 through $n {
        $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
    }
    @return unquote($value);
}
  

$shadows-small:  multiple-box-shadow(700);
$shadows-medium: multiple-box-shadow(200);
$shadows-big:    multiple-box-shadow(100);

#stars {
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;
  animation: animStar 50s linear infinite;
}
    
#stars:after {
content: " ";
position: absolute;
top: 2000px;
width: 1px;
height: 1px;
background: transparent;
box-shadow: $shadows-small;
}

#stars2 {
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;
  animation: animStar 100s linear infinite;
}

#stars2:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
}

#stars3 {
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;
  animation: animStar 150s linear infinite;
}

#stars3:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.app-container {
  text-align: center;
}

body #app .p-button {
  margin-left: 0.2em;
}

form {
  margin-top: 2em;
}

#wrapper {
  height: 100%;
  background: radial-gradient(ellipse at bottom, #022A83 0%, #282828 100%);
  overflow: hidden;
}

@keyframes animStar {
  from	{
      transform: translateY(0px);
  }
    
  to {
      transform: translateY(-2000px);
  }		
    
}

</style>
