<template>
    <div class="surface-card p-4 shadow-2 border-round w-full">
  <div v-if="!user" class="">
    <h2 class="text-center">Additional Features</h2>
    <p>Interested in verifying emails? Log in to access.</p>
        <Button
          @click="userSignInGoogle"
          label="Authenticate with Google"
          icon="pi pi-google"
          class="w-full p-button-lg mb-4"
        />
  </div>
  <div v-else-if="user" class="grid">
    <div class="col-12 text-center">
      <span class="text-500">Welcome</span>
    </div>
    <div class="col-12">
      <Button
        @click="userSignOut"
        label="Sign Out"
        icon="pi pi-sign-out"
        class="w-full p-button p-button-danger"
      />
    </div>
  </div>

  <div v-else class="col-offset-5">
    <ProgressSpinner />
  </div>
</div>
</template>

<script>
import { fireauth } from "@/firebase";
import {
  createUserWithEmailAndPassword,
  updateProfile,
  signInWithPhoneNumber,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  RecaptchaVerifier,
} from "firebase/auth";

export default {
  name: "UserAuth",
  props: ["user"],
  emits: ["checkLogin"],
  data() {
    return {
      stage: 1,
      signInMode: null,
      showCreate: false,
      email: "",
      password: "",
      displayName: "",
      phone: "",
      phoneAccess: false,
      appVerifier: null,
      phoneCode: "",
      confirmationResult: null,
    };
  },
  methods: {
    userSignUp() {
      if (
        this.email.length > 0 &&
        this.password.length > 0 &&
        this.displayName.length > 0
      ) {
        this.stage = 0;
        createUserWithEmailAndPassword(fireauth, this.email, this.password)
          .then(() => {
            updateProfile(fireauth.currentUser, {
              displayName: this.displayName,
            }).then(() => {
              this.stage = 1;
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail:
                "There was an error creating your account. Refresh the page and try again.",
              life: 3000,
            });
            this.stage = 1;
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "All fields are required",
          life: 2000,
        });
      }
    },
    userPhoneSignIn() {
      signInWithPhoneNumber(fireauth, "+1" + this.phone, this.appVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          this.phoneAccess = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    submitAccessCode() {
      this.stage = 0;
      this.confirmationResult
        .confirm(this.phoneCode)
        .then(() => {
          this.stage = 1;
        })
        .catch((error) => {
          console.log(error);
          this.stage = 1;
        });
    },
    userSignIn() {
      this.stage = 0;
      signInWithEmailAndPassword(fireauth, this.email, this.password)
        .then(() => {
          this.stage = 1;
        })
        .catch((error) => {
          console.log(error);
          this.stage = 1;
        });
    },
    userSignInGoogle() {
        const provider = new GoogleAuthProvider();
        signInWithPopup(fireauth, provider)
        .then(() => {
            this.$emit("checkLogin");
        }).catch((error) => {
            console.log(error);
            this.error = error;
        });
    },
    userSignOut() {
      this.stage = 0;
      signOut(fireauth)
        .then(() => {
          setTimeout(() => {
            this.stage = 1;
          }, 1000);
        })
        .catch((error) => {
          console.log(error);
          this.stage = 1;
        });
    },
  },
  watch: {
    signInMode: function (newValue) {
      if (newValue == "phone") {
        setTimeout(() => {
          window.recaptchaVerifier = new RecaptchaVerifier(
            "phoneSignIn",
            {
              size: "invisible",
              callback: (response) => {
                console.log(response);
                this.userPhoneSignIn();
              },
            },
            fireauth
          );
          this.appVerifier = window.recaptchaVerifier;
        }, 100);
      }
    },
  },
};
</script>
