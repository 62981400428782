<template>
    <div class="surface-card p-4 shadow-2 border-round w-full">
    <h1 class="text-center">Mode</h1>
    <div class="text-center">
        <span style="height: 1.75rem; vertical-align: super;">Generate</span>
        <InputSwitch v-model="modeSelection" falseValue="generate" trueValue="compare" @change="optionChange" class="mx-2" />
        <span style="height: 1.75rem; vertical-align: super;">Compare</span>
    </div>
    <p class="text-700 text-sm mt-3">
        <span>Generate: Create a hash of your own CSV file.</span><br /><br />
        <span>Compare: Compare your CSV with a received hash file.</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "Options",
  emits: ["optionChange"],
  data() {
      return {
        modeSelection: false,
      }
  },
  methods: {
      optionChange() {
          this.$emit("optionChange", this.modeSelection);
      }
  }
};
</script>
