<template>
    <div class="surface-card p-4 shadow-2 border-round w-full">
    <h2 class="text-center">Generate Hashfile from CSV</h2>
    <div class="text-center">
        <!-- <FileUpload mode="basic" name="files" url="https://us-east4-opta-hasher.cloudfunctions.net/hasher" @before-send="setHeaders" @upload="uploadComplete" :auto="true" /> -->
        <FileUpload mode="basic" name="files" :customUpload="true" @uploader="fetchForHash" :auto="true" />
    </div>
    <p class="text-sm text-700">Your file must be a CSV that at least includes "email" or "phone" columns (with lowercase headers). Both columns are accepted.</p>
  </div>
</template>

<script>
export default {
  name: "GenerateHash",
  props: {
  },
  methods: {
      setHeaders(request) {
        let authorizationBasic = window.btoa("pineapple:" +  "pears");
        request.xhr.setRequestHeader('Authorization', 'Basic ' + authorizationBasic);
        return request;
      },
      fetchForHash(event) {
        let authorizationBasic = window.btoa("pineapple:" +  "pears");
        let formData = new FormData();
        formData.append( 'files', event.files[0] );
        formData.append( 'mode', "hash" );

        fetch( 'https://hasher-sn3ebet2nq-uk.a.run.app', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + authorizationBasic,
            },
            body: formData
        } )
        .then( function( response ){
            if( response.status != 200 ){
                console.log(response.status, response);
            }else{
                response.blob().then(blob => {
                    let file = window.URL.createObjectURL(blob, {type: "text/csv"});
                    // window.location.assign(file); // This is interpreted without extension or filename
                    let a = document.createElement('a');
                    a.href = file;
                    a.download = "HashByOptakit.csv";
                    a.click();
                })
                // response.json().then( function( data ){
                //     console.log(data);
                // });
            }
        })
        .catch((error) => {
            console.log("Error:", error);
        });
      },
  }
};
</script>
