<template>
    <div class="surface-card p-4 shadow-2 border-round w-full">
    <h2 class="text-center">Compare CSV with Hashfile</h2>
    <div v-if="stage == 0" class="text-center">
        <FileUpload mode="basic" name="files" :customUpload="true" @uploader="prepareCompare" :auto="true" chooseLabel="Choose Hashfile" />
    </div>
    <div v-if="stage == 1" class="text-center">
        Choose comparison file
        <p class="text-sm text-700">Your (received) hash file must be a CSV that includes a "hashed_email" OR "hashed_phone" column. If both are present, only the "hashed_email" column will be compared.</p>
        <FileUpload mode="basic" name="files" :customUpload="true" @uploader="fetchForCompare" :auto="true" chooseLabel="Choose Comparison File" />
    </div>
    
  </div>
</template>

<script>
export default {
  name: "CompareHash",
  props: {
  },
  data() {
      return {
        mode: "compare",
        hashfile: null,
        stage: 0,
      }
  },
  methods: {
    prepareCompare(event) {
        this.hashfile = event.files[0];
        this.stage ++;
        console.log("Fired! Stage:", this.stage);
    },
    fetchForCompare(event) {
        let authorizationBasic = window.btoa("pineapple:" +  "pears");
        let formData = new FormData();
        formData.append( 'files[0]', this.hashfile );
        formData.append( 'files[1]', event.files[0] );
        formData.append( 'mode', "compare" );

        fetch( 'https://hasher-sn3ebet2nq-uk.a.run.app', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + authorizationBasic,
            },
            body: formData
        } )
        .then( function( response ){
            if( response.status != 200 ){
                console.log(response.status, response);
            }else{
                response.blob().then(blob => {
                    let file = window.URL.createObjectURL(blob, {type: "text/csv"});
                    // window.location.assign(file); // This is interpreted without extension or filename
                    let a = document.createElement('a');
                    a.href = file;
                    a.download = "ComparisonByOptakit.csv";
                    a.click();
                })
                // response.json().then( function( data ){
                //     console.log(data);
                // });
            }
        })
        .catch((error) => {
            console.log("Error:", error);
        });
    }
  }
};
</script>
