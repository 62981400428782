import { initializeApp } from "firebase/app";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    apiKey: "AIzaSyA5286RNwMITKxt10cQJF-DUs_8T-F44NE",
    authDomain: "opta-hasher.firebaseapp.com",
    projectId: "opta-hasher",
    storageBucket: "opta-hasher.appspot.com",
    messagingSenderId: "54704739370",
    appId: "1:54704739370:web:bd576511d9cb31121cdf53",
    measurementId: "G-GH33QXJR2H"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const fireauth = getAuth(app);
const firedb = getFirestore();
const analytics = getAnalytics(app);
const fireperf = getPerformance(app);

setPersistence(fireauth, browserLocalPersistence);

export { fireauth, firedb, analytics, fireperf };
